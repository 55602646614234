/* ReceiptCard.css */

.receipt-page-container {
  max-width: 18cm;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  background-color: #fff;
  border: 1px solid #4CAF50;
  border-radius: 10px;
}

.receipt-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.receipt-logo {
  width: 50px;
  height: 50px;
  margin-right: 15px;
}

.receipt-title {
  font-size: 20px; /* Reduced by 2px */
  color: #4CAF50;
  text-align: center;
}

.receipt-divider {
  border: none;
  border-top: 2px solid #4CAF50;
  margin: 10px 0;
}

.receipt-details {
  display: flex;
  flex-direction: column;
}

.receipt-row {
  display: flex;
  justify-content: space-between;
  margin: 6px 0;
}

.receipt-row strong {
  width: 40%;
  text-align: left;
  font-size: 12px; /* Reduced by 2px */
}

.receipt-row span {
  width: 55%;
  text-align: right;
  font-size: 12px; /* Reduced by 2px */
}

.receipt .message {
  margin-top: 20px;
  font-weight: bold;
  color: #333;
  display: flex;
  justify-content: space-between;
  font-size: 12px; /* Reduced by 2px */
}

.id-cards {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin: 20px 0;
  position: relative;
}

/* ID Card Styles */
.id-card {
  width: 8.5cm;
  height: 5.5cm;
  background-color: white;
  border: 1px solid #4CAF50;
  border-radius: 10px;
  overflow: hidden;
  position: relative; /* Ensure positioning context for absolute footer */
  font-size: 8px; /* Reduced by 2px */
  display: flex;
  flex-direction: column;
}

/* ID Card Header */
.id-card-header {
  background-color: #4CAF50;
  color: white;
  padding: 2px;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: -10px;
}

.id-card-title {
  font-size: 8px; /* Reduced by 2px */
  margin: 0 auto;
  text-align: center;
}

.id-card-header-info {
  position: absolute;
  top: 2px;
  right: 5px;
  text-align: right;
  font-size: 6px; /* Reduced by 2px */
}

.id-card-header-info p {
  margin: 1px 0;
}

/* ID Card Logo */
.id-card-logo {
  width: 20px;
  height: 20px;
  margin-left: 5px;
}

/* Back Card Ribbon */
.id-card.back .id-card-header {
  background-color: #4CAF50;
  position: relative;
  overflow: visible;
}

.id-card.back .id-card-header::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 50%;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent; 
  /* border-top: 5px solid #4CAF50;  */
  transform: translateX(-50%);
}

/* ID Card Body */
.id-card-body {
  padding: 2px;
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal-width columns */
  grid-template-rows: auto auto;  /* Two rows: one for sections 1a & 1b, one for section 2 */
  grid-template-areas:
    "section1a section1b";
      padding-bottom: 1px; /* Adjust as needed to accommodate footer */
  position: relative;
}



/* ID Card Footer Modifications */
.id-card-footer {
  background-color: #f1f1f1;
  padding: 2px; /* Reduced from 3px to 2px */
  text-align: center;
  font-size: 4px; /* Reduced font size by 2px */
  position: absolute; /* Position it absolutely within .id-card */
  bottom: 5px; /* Spacing from bottom */
  left: 50%;
  transform: translateX(-50%); /* Center horizontally */
  width: 90%; /* Adjust width as needed */
  box-sizing: border-box; /* Ensure padding doesn't affect width */
  z-index: 10; /* Ensure it stays above other elements */
  
  /* Flexbox to center content vertically and horizontally */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
}

/* Remove default margins and adjust padding for <p> elements inside the footer */
.id-card-footer p {
  margin: 0; /* Remove default margins */
  padding: 0; /* Remove default padding */
  line-height: 1.5; /* Adjust line-height to minimize gaps */
  font-size: 8px; /* Ensure font size is set */
}

/* Front ID Card Modifications */
.id-card.front .id-card-body .id-card-main-section {
  display: flex;
  /* flex-direction: row; */
}

/* ID Card Photo */
.id-card-photo { 
  width: 40px; /* Adjust as needed */
  height: 60px; /* Adjusted height */
  border: 1px solid #4CAF50;
  overflow: hidden;
  margin-right: 2px;
  margin-bottom: 10px; /* Added for space below */
  flex-shrink: 0;
  border-radius: 50%; /* Changed to 50% for a perfect circle */
}

.id-card-photo img {
  width: 100%; /* Changed to 100% to fill the container */
  height: 100%;
  object-fit: cover;
}

.photo-placeholder {
  width: 100%; /* Changed to 100% to fill the container */
  height: 100%;
  background-color: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #777;
  font-size: 6px; /* Reduced by 2px */
  border-radius: 50%; /* Changed to 50% for a perfect circle */
}

/* Front ID Card Main Section */
.id-card-front-main {
  padding-top: 20px;
  flex: 1;
}

.id-card-front-main p {
  margin: 2px 0;
  font-size: 10px; /* Increased font size */
}

.id-card.front .id-card-front-main p {
  font-size: 10px;
}

.id-card-front-section {
  font-size: 6px; /* Reduced font size */
  margin-top: 4px; /* Added for spacing */
}

.id-card-front-section p {
  margin: 1px 0;
}

/* Watermark for Front ID Card */
.id-card.front .id-card-body::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 65%;
  width: 100px;
  height: 100px;
  background-image: url('../assets/logo.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  opacity: 0.1;
  transform: translate(-50%, -50%);
  z-index: 0;
}

/* Watermark for Back ID Card */
.id-card.back .id-card-body::before {
  content: "";
  position: absolute;
  top: 60%;
  left: 50%;
  width: 150px;
  height: 150px;
  background-image: url('../assets/logo.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  opacity: 0.1;
  transform: translate(-50%, -50%);
  z-index: 0;
}

/* Scissor Mark */
.scissor-mark {
  width: 2cm;
  height: auto;
  margin: 0 5px;
}
/* Back ID Card Modifications */
.id-card.back .id-card-body {
  display: flex;
  flex-direction: column;
  padding: 5px;
  padding-top: 20px;
  position: relative;
  flex: 1;
}

/* Container for the two columns */
.id-card.back .id-card-section-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.id-card-section-row p {
  margin: 1px 0;
}

/* First Column */
.id-card-section-1a {
  width: 48%;
  padding: 2px;
}

/* Second Column */
.id-card-section-1b {
  width: 48%;
  padding: 2px;
}

/* General Styling for Sections */
.id-card.back .id-card-section-1a,
.id-card.back .id-card-section-1b,
.id-card.back .id-card-section-2 {
  font-size: 9px;

}
.id-card-section-2 {
  line-height: 1.0;
}


/* Responsive Design */
@media (max-width: 768px) {
  .id-cards {
    flex-direction: column;
    align-items: center;
  }

  .id-card {
    width: 8.5cm;
    height: auto;
    margin-bottom: 20px;
  }

  .scissor-mark {
    display: none;
  }

  .receipt-page-container {
    width: 100%;
    padding: 10px;
  }

  .id-card-body {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto; /* Adjust rows for single-column layout */
    grid-template-areas:
      "section1a"
      "section1b"
      "section2";
  }

  .id-card-section-1a,
  .id-card-section-1b,
  .id-card-section-2 {
    width: 100%;
  }

  .id-card-section-1a,
  .id-card-section-1b {
    display: block;
  }

  .id-card-section-2 {
    padding-top: -10px;
    line-height: 1;

  }

  .id-card-footer p{
    font-size: 2px; /* Further reduce font size if necessary */
  }

  @media (max-width: 768px) {
    .id-card.back .id-card-section-row {
      flex-direction: column;
    }
    .id-card-section-1a,
    .id-card-section-1b {
      width: 100%;
    }
  }
}
