
/* IDCard.css */
.id-card-container {
  text-align: center;
  margin: 20px auto;
  max-width: 400px;
}

.id-card {
  width: 100%;
  margin: 0 auto;
  font-family: 'Arial', sans-serif;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
}

.id-card-side {
  background-color: white;
  color: #333;
  border: 1px solid #4CAF50;
}

.id-card-header {
  background-color: #4CAF50;
  color: white;
  padding: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.id-card-logo {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.id-card-header h2 {
  font-size: 16px;
  margin: 0;
}

.id-card-body {
  padding: 15px;
  display: flex;
}

.id-card-photo {
  width: 100px;
  height: 120px;
  border: 2px solid #4CAF50;
  overflow: hidden;
  margin-right: 15px;
}

.photo-placeholder {
  width: 100%;
  height: 100%;
  background-color: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #777;
  font-size: 12px;
}

.id-card-details {
  flex: 1;
  text-align: left;
}

.id-card-details p {
  margin: 5px 0;
  font-size: 14px;
}

.id-card-address {
  text-align: left;
}

.id-card-address h3 {
  font-size: 16px;
  margin-bottom: 5px;
}

.id-card-address p {
  margin: 2px 0;
  font-size: 14px;
  white-space: pre-line;
}

.id-card-footer {
  font-size: 12px;
  margin-top: 10px;
  text-align: left;
}

.id-card-logo-back {
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 10px;
  right: 5%;
}

.scissor-line {
  margin: 10px 0;
  font-size: 20px;
  color: #777;
}

@media print {
  .id-card-container button {
    display: none;
  }
  
  .id-card {
    page-break-inside: avoid;
  }
  
  .scissor-line {
    page-break-after: always;
  }
}