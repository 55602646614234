/* src/App.css */
body {
    font-family: Arial, sans-serif;
    background-color: #f0f0f0;
    margin: 0;
    padding: 0;
  }
  .header {
    background: linear-gradient(135deg, #4CAF50 0%, #45a049 100%);
    padding: 20px 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .header-content {
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .logo {
    width: 90px;
    height: 90px;
    margin-right: 10px;
  }
  
  .title {
    color: white;
    font-size: 2.5rem;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .language-selector-container {
    position: absolute;
    bottom: 10px;
    right: 20px;
  }
  
  .language-selector {
    display: inline-block;
  }
  
  .toggle-switch {
    position: relative;
    width: 60px;
    user-select: none;
  }
  
  .toggle-switch-checkbox {
    display: none;
  }
  
  .toggle-switch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 2px solid #ffffff;
    border-radius: 20px;
  }
  
  .toggle-switch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
  }
  
  .toggle-switch-inner:before,
  .toggle-switch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 30px;
    padding: 0;
    line-height: 30px;
    font-size: 14px;
    color: white;
    font-weight: bold;
    box-sizing: border-box;
  }
  
  .toggle-switch-inner:before {
    content: "EN";
    padding-left: 10px;
    background-color: #4CAF50;
    color: #FFFFFF;
  }
  
  .toggle-switch-inner:after {
    content: "తె";
    padding-right: 10px;
    background-color: #CCCCCC;
    color: #333333;
    text-align: right;
  }
  
  .toggle-switch-switch {
    display: block;
    width: 18px;
    height: 18px;
    margin: 6px;
    background: #FFFFFF;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 30px;
    border: 2px solid #ffffff;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
  }
  
  .toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
    margin-left: 0;
  }
  
  .toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
    right: 0px;
  }
  .app-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .content-container {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  h1, h2 {
    color: #333;
  }
  
  button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  button:hover {
    background-color: #45a049;
  }
  
  select {
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 20px;
  }

  /* Add this to your existing App.css */

.select-container {
    position: relative;
    width: 200px;
  }
  
  .select-trigger {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .select-value {
    flex-grow: 1;
  }
  
  .arrow {
    border: solid #333;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transition: transform 0.2s;
  }
  
  .arrow.down {
    transform: rotate(45deg);
  }
  
  .arrow.up {
    transform: rotate(-135deg);
  }
  
  .select-content {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
    z-index: 1;
  }
  
  .select-item {
    padding: 10px;
    cursor: pointer;
  }
  
  .select-item:hover {
    background-color: #f0f0f0;
  }
  
  /* .language-selector {
    margin-bottom: 20px;
  } */
  /* Add this to your existing App.css */

.form-field {
    margin-bottom: 1rem;
  }
  
  .form-field label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .form-field input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
  }
  
  .card-header {
    margin-bottom: 1rem;
  }
  
  .card-content {
    padding: 1rem 0;
  }
  
  button[type="submit"] {
    background-color: #4CAF50;
    color: white;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
  }
  
  button[type="submit"]:hover {
    background-color: #45a049;
  }

  /* Add these styles to your existing App.css */

.photo-upload {
  margin-bottom: 20px;
}

.photo-upload img.cropped-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.photo-upload .photo-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.cropper-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cropper-container {
  position: relative;
  width: 80%;
  height: 400px;
  background-color: #333;
}

.cropper-buttons {
  margin-top: 20px;
  display: flex;
  gap: 10px;
}

.placeholder-image {
  width: 100%;
  height: 200px;
  background-color: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
  margin-bottom: 10px;
}

/* Add these styles to your existing App.css */

.id-card-container {
  text-align: center;
  margin-top: 20px;
}

.id-card {
  width: 350px;
  margin: 0 auto;
  perspective: 1000px;
}

.id-card-front, .id-card-back {
  width: 100%;
  height: 220px;
  border: 1px solid #ccc;
  border-radius: 10px;
  backface-visibility: hidden;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  padding: 10px;
}

.id-card-header {
  text-align: center;
}

.id-card-logo {
  width: 50px;
  height: 50px;
}

.id-card-photo img {
  width: 100px;
  height: 120px;
  object-fit: cover;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.photo-placeholder {
  width: 100px;
  height: 120px;
  background-color: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
}

.id-card-details {
  text-align: left;
  margin-top: 10px;
}

.id-card-back .id-card-logo-back {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.id-card-back .id-card-logo-back img {
  width: 40px;
  height: 40px;
}
